@import "config/grid.yaml";
@import "config/templates.yaml";
@import "config/theme.yaml";
@import "config/woocommerce.yaml";
@import "~@nematis/wp-alpha/assets/sass/abstracts/yaml-variables";
@import "assets/sass/init";
@import "../init";

// === WP ALPHA ASSETS
@import "~@nematis/wp-alpha/assets/sass/pages/page";

// === COMPONENTS
@import "../components/swiper";

.banner-between-blocks {
  max-height: 200px;
  img {
    max-height: 200px !important;
  }
}
