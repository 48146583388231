.swiper-nav-white {
  --swiper-navigation-color: #fff;
}

.swiper-pagination-primary {
  --swiper-pagination-color: #{$primary};
}

.swiper-pagination-secondary {
  --swiper-pagination-color: #{$secondary};
}
